import 'whatwg-fetch'

let button, plantedTreesLabel, plantedText, alreadyPlantedText, container, seedImage, treeImage

const updateStatus = async () => {
  const statusRequest = await fetch('/api.php?action=status')
  const status = await statusRequest.json()
  plantedTreesLabel.innerHTML = status.plantedCount
}

const handleCode = async (code: string) => {
  const codeStatusRequest = await fetch('/api.php?action=check&code=' + code)
  const status = await codeStatusRequest.json()

  if (status.error) {
    alert(status.error)
    return
  }

  const { planted } = status
  if (!planted) {
    button.classList.add('button--visible')
    treeImage.classList.add('hidden')
    seedImage.classList.remove('hidden')
  } else {
    alreadyPlantedText.classList.add('plantedText--visible')
    treeImage.classList.remove('hidden')
    seedImage.classList.add('hidden')
    return
  }

  button.addEventListener('click', async () => {
    button.classList.add('button--faded')

    // Plant the tree
    await fetch('/api.php?action=plant&code=' + code)
    await updateStatus()

    setTimeout(() => {
      plantedText.classList.add('plantedText--visible')
    }, 500)

    treeImage.classList.remove('hidden')
    seedImage.classList.add('hidden')
  })
}

window.addEventListener('load', async () => {
  // Find elements
  container = document.querySelector('.js-container')
  button = document.querySelector('.js-plantButton')
  plantedTreesLabel = document.querySelector('.js-plantedTrees')
  plantedText = document.querySelector('.js-plantedText')
  alreadyPlantedText = document.querySelector('.js-alreadyPlantedText')
  seedImage = document.querySelector('.js-seedImage')
  treeImage = document.querySelector('.js-treeImage')

  // Grab code from URL
  const pathname = window.location.pathname
  const code = pathname.split('/').pop()
  if (code) {
    await handleCode(code)
  }

  // Grab planted count
  await updateStatus()

  container.classList.add('container--visible')
})
